import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Pagination from "../components/Pagination";
import EventListItem from "../components/ListItems/EventListItem";
import Breadcrumb from "../components/Breadcrumb";

const EventList = ({pageContext, data, location}) => {
    const {
        currentPage,
        numPages,
    } = pageContext

    return (
        <Layout>
            <Metas title="Agenda"/>
            <section className="section page-content">
                <div className="container mx-auto px-4">
                    <Breadcrumb/>

                    <h1 className="title h1 text-primary" data-typesense-field="title">Agenda</h1>

                    <div className="columns grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        {data.events.nodes.map((node, index) => (
                            <div key={index} className="column event">
                                <EventListItem content={node}/>
                            </div>
                        ))}
                    </div>

                    <Pagination
                        path={location.pathname}
                        currentPage={currentPage}
                        numPages={numPages}
                    />
                </div>
            </section>
        </Layout>
    )
}

export default EventList

export const query = graphql`query eventListQuery($skip: Int!, $limit: Int!) {
  events: allNodeEvenement(
    filter: {status: {eq: true}, is_forthcoming: {eq: true}}
    sort: {fields: field_date___value, order: ASC}
    limit: $limit
    skip: $skip
  ) {
    nodes {
      id
      title
      dates: field_date {
        start: value(formatString: "X", locale: "fr")
        startDay: value(formatString: "DD", locale: "fr")
        startMonth: value(formatString: "MMM", locale: "fr")
        startYear: value(formatString: "YYYY", locale: "fr")
        end: end_value(formatString: "X", locale: "fr")
        endDay: end_value(formatString: "DD", locale: "fr")
        endMonth: end_value(formatString: "MMM", locale: "fr")
        endYear: value(formatString: "YYYY", locale: "fr")
      }
      emplacement: field_emplacement
      relationships {
        image: field_image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 352
                height: 222
                quality: 100
                transformOptions: {cropFocus: CENTER}
                layout: CONSTRAINED
              )
            }
          }
        }
      }
      path {
        alias
      }
    }
  }
}
`
